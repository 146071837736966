exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kiri-saadetud-js": () => import("./../../../src/pages/kiri-saadetud.js" /* webpackChunkName: "component---src-pages-kiri-saadetud-js" */),
  "component---src-templates-a-dok-page-js": () => import("./../../../src/templates/a-dok-page.js" /* webpackChunkName: "component---src-templates-a-dok-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-meist-page-js": () => import("./../../../src/templates/meist-page.js" /* webpackChunkName: "component---src-templates-meist-page-js" */),
  "component---src-templates-partnerile-page-js": () => import("./../../../src/templates/partnerile-page.js" /* webpackChunkName: "component---src-templates-partnerile-page-js" */),
  "component---src-templates-privaatsuspoliitika-page-js": () => import("./../../../src/templates/privaatsuspoliitika-page.js" /* webpackChunkName: "component---src-templates-privaatsuspoliitika-page-js" */)
}

